$(document).ready(function () {
  $("#testimonial-slider").owlCarousel({
    pagination: true,
    loop: true,
    dots: true,
    autoplay: true,
    autoplayHoverPause: true,
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 2,
      },
      1000: {
        items: 3,
      },
    },
  });

  $(".collapse.show").each(function () {
    debugger;
    $(this).parent().find(".fa").removeClass("fa-plus").addClass("fa-minus");
  });

  $(".collapse")
    .on("shown.bs.collapse", function () {
      $(this).parent().find(".fa").removeClass("fa-plus").addClass("fa-minus");
    })
    .on("hidden.bs.collapse", function () {
      $(this).parent().find(".fa").removeClass("fa-minus").addClass("fa-plus");
    });

  const animationData = [
    {
      trigger: ".g-our-benefits-2",
      from: { y: -200, opacity: 0, duration: 1.5, lazy: true },
      to: { y: 0, opacity: 1, duration: 1.5 },
    },
    {
      trigger: ".g-our-benefits-4",
      from: { x: -200, opacity: 0, duration: 1.5, lazy: true },
      to: { x: 0, opacity: 1, duration: 1.5 },
    },
    {
      trigger: ".g-our-benefits-6",
      from: { x: 200, opacity: 0, duration: 1.5 },
      to: { x: 0, opacity: 1, duration: 1.5 },
    },
    {
      trigger: ".g-our-benefits-8",
      from: { y: 200, opacity: 0, duration: 1.5 },
      to: { y: 0, opacity: 1, duration: 1.5 },
    },
    {
      trigger: ".g-remote-developers-image",
      from: { x: -200, opacity: 0, duration: 1.5 },
      to: { x: 0, opacity: 1, duration: 1.5 },
    },
    {
      trigger: ".g-hire-top-developers-image",
      from: { x: 200, opacity: 0, duration: 1.5 },
      to: { x: 0, opacity: 1, duration: 1.5 },
    },
    {
      trigger: ".animate-faq-image",
      from: { x: -200, opacity: 0, duration: 1.5 },
      to: { x: 0, opacity: 1, duration: 1.5 },
    },
  ];

  animationData.forEach((data) => {
    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: data.trigger,
        toggleActions: "play pause resume pause",
      },
    });
    tl.from(data.trigger, data.from);
    tl.to(data.trigger, data.to);
  });

  const bannerLogos = gsap.utils.toArray(".animate-banner-logos");
  bannerLogos.forEach((card, i) => {
    gsap.from(card, {
      scrollTrigger: {
        trigger: card,
        toggleActions: "play pause resume pause",
      },
      y: 100,
      opacity: 0,
      duration: 0.8,
      delay: i * 0.1,
      ease: "power1.out",
    });
  });

  bannerLogos.forEach((card, i) => {
    gsap.to(card, {
      scrollTrigger: {
        trigger: card,
        toggleActions: "play pause resume pause",
      },
      y: 0,
      opacity: 1,
      duration: 0.8,
    });
  });

  const dCards = gsap.utils.toArray(".g-developer-card");
  dCards.forEach((card, i) => {
    gsap.from(card, {
      scrollTrigger: {
        trigger: card,
        toggleActions: "play pause resume pause",
      },
      y: 100,
      opacity: 0,
      duration: 0.8,
    });
  });

  dCards.forEach((card, i) => {
    gsap.to(card, {
      scrollTrigger: {
        trigger: card,
        toggleActions: "play pause resume pause",
      },
      y: 0,
      opacity: 1,
      duration: 0.8,
    });
  });

  const rCards = gsap.utils.toArray(".r-box");
  rCards.forEach((card, i) => {
    gsap.from(card, {
      scrollTrigger: {
        trigger: card,
        toggleActions: "play pause resume pause",
      },
      y: 100,
      opacity: 0,
      duration: 1,
    });
  });

  rCards.forEach((card, i) => {
    gsap.to(card, {
      scrollTrigger: {
        trigger: card,
        toggleActions: "play pause resume pause",
      },
      y: 0,
      opacity: 1,
      duration: 1,
    });
  });

  const hCards = gsap.utils.toArray(".how-to-box");
  hCards.forEach((card, i) => {
    gsap.from(card, {
      scrollTrigger: {
        trigger: card,
        toggleActions: "play pause resume pause",
      },
      y: 100,
      opacity: 0,
      duration: 0.8,
    });
  });

  hCards.forEach((card, i) => {
    gsap.to(card, {
      scrollTrigger: {
        trigger: card,
        toggleActions: "play pause resume pause",
      },
      y: 0,
      opacity: 1,
      duration: 0.8,
    });
  });

  const connectSection = gsap.utils.toArray(".animate-connect");
  connectSection.forEach((card, i) => {
    gsap.from(card, {
      scrollTrigger: {
        trigger: card,
        toggleActions: "play pause resume pause",
      },
      y: 130,
      opacity: 0,
      duration: 0.8,
    });
  });

  connectSection.forEach((card, i) => {
    gsap.to(card, {
      scrollTrigger: {
        trigger: card,
        toggleActions: "play pause resume pause",
      },
      y: 0,
      opacity: 1,
      duration: 0.8,
    });
  });

  const revealText = new SplitType(".reveal-text");

  gsap.to(".char", {
    y: 0,
    stagger: 0.05,
    delay: 0.5,
    duration: 1,
    scrollTrigger: {
      trigger: ".reveal-text",
    },
  });

  ScrollTrigger.refresh();

  let tl9 = gsap.timeline();

  tl9.from(".animate-about-us-heading", { y: 50, opacity: 0, duration: 1.5 });
  tl9.to(".animate-about-us-heading", { y: 0, opacity: 1, duration: 1.5 });

  const team = gsap.utils.toArray(".animate-team");
  team.forEach((card, i) => {
    gsap.from(card, {
      scrollTrigger: {
        trigger: card,
        toggleActions: "play pause resume pause",
      },
      y: 130,
      opacity: 0,
      duration: 0.8,
    });
  });

  team.forEach((card, i) => {
    gsap.to(card, {
      scrollTrigger: {
        trigger: card,
        toggleActions: "play pause resume pause",
      },
      y: 0,
      opacity: 1,
      duration: 0.8,
    });
  });

  jQuery(".skills-link").click(function (e) {
    e.preventDefault();
    window.scrollTo({
      top: jQuery(".find-remote-developers-section").offset().top,
      behavior: "smooth",
    });
  });

  jQuery(".process-link").click(function (e) {
    e.preventDefault();
    var viewportHeight = jQuery(window).height();
    window.scrollTo({
      top: jQuery(".how-to-get-started").offset().top - viewportHeight / 4,
      behavior: "smooth",
    });
  });

  var scrollTopBtn = document.querySelector(".scrollTopBtn");
  window.onscroll = function () {
    scrollFunction();
  };

  function scrollFunction() {
    if (document.body.scrollTop > 120 || document.documentElement.scrollTop > 120) {
      scrollTopBtn.style.display = "block";
      TweenLite.to(scrollTopBtn, 0.5, { right: "20px", ease: Power2.easeOut });
    } else {
      scrollTopBtn.style.display = "none";
      TweenLite.to(scrollTopBtn, 0.5, { right: "-60px", ease: Power2.easeOut });
    }
  }

  function topFunction() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }

  scrollTopBtn.addEventListener("click", topFunction);
});
